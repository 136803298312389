import type { Reroute } from "@sveltejs/kit";
import { i18n } from "$lib/i18n";

export const reroute: Reroute = i18n.reroute();

// export const reroute: Reroute = (event) => {
// 	console.log(event.url)
// 	// Example: rerouting for another domain
// 	if (event.url.hostname === "heimscout.se") {
// 		return `/heimscout/${event.url.pathname}`;
// 	}

//     try {
//         const i18nReroute = i18n.reroute(); // Pass url directly, no object wrapping
//         console.log('i18nReroute:', i18nReroute);

//         if (i18nReroute) {
//             return i18nReroute;
//         }
//     } catch (error) {
//         console.error('Error in i18n reroute:', error);
//     }

// 	// Default: no reroute, just return null or undefined
// 	return null;
// };

// npx playwright test
// npx vitest
